export const firebaseConfig = {
  apiKey: 'AIzaSyAVTdVMyGs3kcAvC2hYwM0Bdqdiu1hTGGA',
  authDomain: 'mrdscheduler-69e0f.firebaseapp.com',
  databaseURL: 'https://mrdscheduler-69e0f-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'mrdscheduler-69e0f',
  storageBucket: 'mrdscheduler-69e0f.appspot.com',
  messagingSenderId: '69252143608',
  appId: '1:69252143608:web:811df324d235f132b01e0d',
  measurementId: 'G-BVQFSSFN92',
};
